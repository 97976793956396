import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

const TermsTable = () => {
  return (
    <Container>
      <p>Particulars of Personal Data to be Processed</p>
      <Table>
        <div>
          <section>The purpose and subject matter of the Processing</section>
          <section>
            BB provide online training courses for Customer's employees and the Customer provides BB with personal data relating to such
            employees to allow the training to be undertaken and to manage the provision of the services
          </section>
        </div>
        <div>
          <section>Duration of Processing</section>
          <section>The term of any Subscription Agreement</section>
        </div>
        <div>
          <section>The type of Personal Data being Processed</section>
          <section>Employee names, email addresses, log in details, telephone numbers</section>
        </div>
        <div>
          <section>The categories of Data Subjects</section>
          <section>Data Subjects are Customer employees.</section>
        </div>
        <div>
          <section>Processing activities</section>
          <section>
            BB hold, transfer and update Personal Data belonging to Customer's employees solely for the purpose of providing the Services
          </section>
        </div>
        <div>
          <section>Any special categories of data</section>
          <section>None</section>
        </div>
      </Table>
    </Container>
  )
}

const Table = styled.div`
  border: 1px solid ${theme.brand.blue};
  font-size: 12px;
  margin-bottom: 100px;
  color: ${theme.text.main};

  & div {
    display: flex;
    border-bottom: 1px solid #eee;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
    }

    & section:first-of-type {
      font-weight: bold;
      flex: 1;
      border-right: 1px solid ${theme.brand.blue};
      padding: 15px 20px;
      background: #f2fafc;

      @media only screen and (max-width: 1023px) {
        border-right: none;
      }
    }

    & section:last-of-type {
      flex: 2;
      padding: 15px 20px;
      font-weight: 300;
    }
  }
`

const Container = styled.div``

export default TermsTable
