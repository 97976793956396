import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

const TermsSection = ({ heading, children }) => {
  return (
    <Container>
      <div>{heading}</div>
      <div>{children}</div>
    </Container>
  )
}

export default TermsSection

const Container = styled.div`
  display: flex;
  width: 92%;
  max-width: 1140px;
  margin: 70px auto 0;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    margin: 0 20px 0;
  }

  & > div:first-of-type {
    position: sticky;
    top: 25px;
    height: 100%;
    width: 340px;
    border-top: 3px solid ${theme.brand.blue};
    padding-top: 28px;
    color: ${theme.brand.blue};
    margin-right: 60px;
    font-weight: bold;
    flex-shrink: 0;
    font-size: 15px;

    @media only screen and (max-width: 850px) {
      width: 100%;
      border-top: 1px solid #eee;
      border-bottom: 1px solid  ${theme.brand.blue};
      padding-bottom: 20px;
      margin-bottom: 40px;
      margin-top: 31px;
      background: white;
      z-index: 3;
      top: 0;
    }
  }

  & > div:last-of-type {
    & p,
    li {
      font-weight: 300;
      font-size: 15px;
      line-height: 25px;
      color: ${theme.text.main};
      margin-bottom: 20px;

      & a {
        font-size: inherit;
      }
    }

    & strong {
      font-weight: bold;
    }

    & h3 {
      color: ${theme.text.main};
      font-size: 20px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        height: 2px;
        width: 15px;
        background: ${theme.text.main};
        display: inline-block;
        margin-left: 10px;
      }
    }

    & ol {
      list-style: none;

      & li {
        display: flex;
      }
      & span {
        margin-right: 22px;
        display: block;
      }
    }

    & > section {
      margin-left: 35px;
    }

    & ul {
      list-style: none;
      margin-left: 30px;

      & li {
        position: relative;
        // &:before {
        //   position: absolute;
        //   top: 10px;
        //   left: -30px;
        //   content: '';
        //   height: 2px;
        //   width: 20px;
        //   background: ${colors.text};
        // }
      }
    }
  }
`
